td {
  text-align: left;
  padding-bottom: 6px;
}

th {
  min-width: 100px;
  font-weight: normal;
}

.align-left {
  text-align: left;
}

.table-info-table {
  margin-top: 0px;
}

.h3-info-table-title {
  margin-bottom: 0px;
}
