.div-pyramid-outer {
}

.div-pyramid {
  display: table-cell;
}

.svg-pyramid {
  margin-top: 24px;
}
