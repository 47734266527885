.div-loader {
  border: 4px solid var(--color-gray-f0);
  border-radius: 50%;
  border-top: 4px solid lightgray;
  width: 16px;
  height: 16px;
  margin: 16px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
