.entity-link {
  font-size: 9px;
}
.entity-link-id {
  opacity: 0.2;
}

.entity-link-name {
  opacity: 1.0;
}

.div-entity-link-list {
  color: red;
}

.div-entity-link-list-list {
  color: red;
}
