.align-right {
  text-align: right;
}

table {
  width: 200px;
}

td {
  vertical-align: top;
  text-align: left;
  padding-bottom: 6px;
}

th {
  width: 120px;
  font-weight: normal;
}

.highlight {
  font-weight: 700;
  color: black;
}
