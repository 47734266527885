:root {
  --color-sl-maroon: #8d2029;
  --color-sl-orange: #ff5b00;
  --color-sl-yellow: #ffb700;
  --color-sl-yellow-light: #fff0ca;
  --color-sl-green: #005641;

  --color-gray-f0: #f0f0f0;
  --color-gray-f8: #f8f8f8;
  --color-gray-fc: #fcfcfc;
  --color-gray-fd: #fdfdfd;
}

body {
  color: black;
  font-family: sans-serif, Arial, Helvetica;
  font-weight: 300;
  line-height: 1.4;
}

h1, h2, h3, p {
  font-weight: 300;
  margin: 0;
  padding: 0;
  padding-bottom: 12px;
  line-height: 1.4;
}

div {
  line-height: 1.4;
}

hr {
  color: #eee;
  border-width: 0.5px;
}

strong {
  color: var(--color-sl-maroon);
  font-weight: 300;
}

a {
  text-decoration: none;
  color: var(--color-sl-green);
}

a:hover {
  color: var(--color-sl-orange);
}
