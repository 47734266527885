.div-detailed-info {
  background: ghostwhite;
  opacity: 0.8;

  border-radius: 6px;
  right: 16px;
  margin: 16px;
  padding: 16px;
  position: absolute;
  top: 16px;
  z-index: 2000;

  width : 400px;
}

.show {
  overflow: scroll;
  height: 90%;
}

.hide {

}

.div-summary-outer {
  max-width: 500px;
}

.div-show-details {
  font-size: 12px;
  color: var(--color-sl-green);
}

.div-show-details:hover {
  cursor: pointer;
  color: var(--color-sl-orange);
}

.div-data-source {
  font-size: smaller;
  font-style: italic;
}
