.div-region-map-data-view, svg {
  position: absolute;
  z-index: 2000;
}

.polyline-region-map {
  stroke: red;
  stroke-width: 1px;
  fill: red;
  fill-opacity: 0.2;
}
