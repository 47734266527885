.div-census-info {
  width: 400px;
  margin-bottom: 72px;
}
.div-census-chart-component {
}

.h4-census-label {
  margin-bottom: 0px;
}

.h3-census-group-name {
  padding-bottom: 0px;
}
